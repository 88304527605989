<template>
  <div :class="getCssClass()">
    <router-view v-slot="{ Component }">
      <keep-alive include="CustomerIndex">
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <Spinner v-if="showSpinnerState" />
    <Confirm />

    <InviteDialog v-if="showMfaInvite" />

    <!--    <MaintenanceModal />-->
  </div>
</template>

<style lang="scss">
@import '@/styles/base.scss';
</style>

<script setup>
import { onMounted, onBeforeMount, nextTick, watch } from 'vue'
import { storeToRefs } from 'pinia'

import Spinner from '@/components/Spinner.vue'
import Confirm from '@/components/Confirm.vue'
import InviteDialog from '@/components/MFA/InviteDialog.vue'
import { useAppStore, useAuthStore } from '@/stores'

const appStore = useAppStore()
const { setHeight, setWidth } = appStore
const { showSpinnerState, isMobile } = storeToRefs(appStore)

const authStore = useAuthStore()
const { showMfaInvite } = storeToRefs(authStore)

onBeforeMount(() => {
  onResize()
})

onMounted(() => {
  nextTick(() => {
    window.addEventListener('resize', onResize)
  })
})

const onResize = () => {
  setHeight(window.innerHeight)
  setWidth(window.innerWidth)
}

const getCssClass = () => {
  return isMobile ? 'isMobile' : 'isNotMobile'
}
</script>
